// QUERYPARAMS
//////////////

// This module handles any query params that are included on any arrival URL that need to be passed on to Shopify
// It holds them in the store until the customer proceeds to checkout
// 'mapTo' can be used to map any that need to be set with a different key when passed on
// If 'mapTo' set to 'null', the original param name is maintained.

const acceptedQueryParams = [
  {
    // Duel passes a couple of params, eg duel_a, duel_b so we can match with regex
    regex: /^duel_*/,
    mapTo: null
  },
  {
    // 'inref' is the 'influencer referral' code, directly maps to Shopify's discount code
    regex: /inref/,
    mapTo: "discount",
    discountValue: 25
  },
  {
    // 'vip_discount' is the default code, directly maps to Shopify's discount code
    regex: /vip_discount/,
    mapTo: "discount",
    discountValue: 35 // Yes this is hardcoded nonsense, wanna fight about it?
  },
  {
    // ShareASale tracking param
    regex: /sscid/,
    mapTo: null
  }
]

export default {
  namespaced: true,

  state: {
    queryParamsMap: {}, // { key: value, ... }
    discountQueryDetail: null
  },

  mutations: {
    SET_QUERY_PARAMS(state, queryParamsMap) {
      state.queryParamsMap = queryParamsMap
    },

    SET_DISCOUNT_QUERY_DETAIL(state, _val) {
      state.discountQueryDetail = _val
    }
  },

  actions: {
    CLEAR_PARAMS({ commit }) {
      commit("SET_QUERY_PARAMS", {})
    },
    PERSIST_PARAMS({ state, commit, dispatch }) {
      const _tempParamsMap = {}
      for (const [queryKey, queryValue] of new URLSearchParams(
        window.location.search
      ).entries()) {
        let _paramIndex = acceptedQueryParams.findIndex(param =>
          queryKey.match(param.regex)
        )
        if (_paramIndex > -1)
          if (
            acceptedQueryParams[_paramIndex] &&
            acceptedQueryParams[_paramIndex].mapTo === "discount"
          ) {
            // Check if it's the highest value discount query param passed in
            if (
              !state.discountQueryDetail ||
              (!!state.discountQueryDetail &&
                acceptedQueryParams[_paramIndex].discountValue >
                  state.discountQueryDetail.discountValue)
            ) {
              const discountObject = {
                discountSource: queryKey,
                discountValue: acceptedQueryParams[_paramIndex].discountValue,
                discountCode: queryValue
              }
              commit("SET_DISCOUNT_QUERY_DETAIL", discountObject)
              dispatch("content/SUBMIT_GLOBAL_DISCOUNT", discountObject, {
                root: true
              })
            }

            commit("")
            // Send this as a global adjustment to the content store
          }
        _tempParamsMap[
          (acceptedQueryParams[_paramIndex] &&
            acceptedQueryParams[_paramIndex].mapTo) ||
            queryKey
        ] = queryValue
      }

      commit("SET_QUERY_PARAMS", _tempParamsMap)
    }
  },

  getters: {
    getHasDiscountQuery: state => {
      // TODO: This is rather literal but for now it's the only measure of whether there is any 'discount' param in the store.
      return !!state.discountQueryDetail
    }

    // getHasValidatedDiscountQuery: (state, getters, rootState, rootGetters) => {
    //   // This filters out any discount codes that aren't on the vipCouponValidator
    //   // TODO: This will eventually be part of the Contentful-sourced discount management
    //   return rootGetters["content/GET_SITE_CONFIG"].vipCouponValidator.includes(
    //     state.discountQueryDetail.discountCode
    //   )
    // }
  }
}
